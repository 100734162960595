<ng-container *ngIf="!href">
    <button (click)="onClick($event)" class="lab-btn {{ variation ? 'lab-btn-' + variation : ''}}  {{ size ? 'lab-btn-' + size : ''}} {{ borderType ? 'lab-btn-' + borderType : '' }}">
        <span *ngIf="icon && iconType == 'material'" class="material-symbols-outlined">
            {{ icon }}
        </span>
    
        <ng-container *ngIf="iconType == 'font-awesome'">
            <fa-icon [icon]="formatIcon(icon)"></fa-icon>
        </ng-container>
    
        <span *ngIf="label.length > 0">
            {{ loading ? 'Carregando...' : label }}
        </span>
    </button>
</ng-container>

<ng-container *ngIf="href">
    <a id="{{id}}" [href]="href.url" [target]="href.target" class="lab-btn {{ variation ? 'lab-btn-' + variation : ''}}  {{ size ? 'lab-btn-' + size : ''}} {{ borderType ? 'lab-btn-' + borderType : '' }}">
        <span *ngIf="icon && iconType == 'material'" class="material-symbols-outlined">
            {{ icon }}
        </span>
    
        <ng-container *ngIf="iconType == 'font-awesome'">
            <fa-icon [icon]="formatIcon(icon)"></fa-icon>
        </ng-container>
    
        <span *ngIf="label.length > 0">
            {{ loading ? 'Carregando...' : label }}
        </span>
    </a>
</ng-container>
