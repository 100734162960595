import { environment } from "../../../environments/environment";

export class ContactHelper {

    static getRandomWhatsAppNumberString(ddd: string = '') {
        const whatsapp = this.getRandomWhatsAppNumber(ddd);

        return {
            url: `https://wa.me/${whatsapp.number}?text=${whatsapp.message}`,
            text: whatsapp.text,
        };
    }

    static getRandomWhatsAppNumber(ddd: string = '') {
        const numbers = environment.contacts.whatsapp;
        if (environment.changeContactByGeolocation) {
            const number = numbers.find((n) => n.number.includes(ddd));

            return number ?? numbers[0];
        }
        return numbers[Math.floor(Math.random() * numbers.length)];
    }

    static getRandomPhoneNumber(ddd: string = '') {
        const numbers = environment.contacts.phone;
        if (environment.changeContactByGeolocation) {
            const number = numbers.find((n) => n.number.includes(ddd));
            return number ?? numbers[0];
        }
        return numbers[Math.floor(Math.random() * numbers.length)];
    }

    static getRandomPhoneNumberString(ddd: string = '') {
        const phone = this.getRandomPhoneNumber(ddd);
        return {
            url: `tel:${phone.number}`,
            text: phone.text,
        };
    }
}