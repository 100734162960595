import { environment } from "../../../environments/environment";

export class ContactHelper {
    static getRandomWhatsAppNumber(ddd: string = '') {
        const numbers = environment.contacts.whatsapp;
        if (environment.changeContactByGeolocation) {
            const number = numbers.find((n) => n.number.includes(ddd));

            return number ?? numbers[0];
        }
        return numbers[Math.floor(Math.random() * numbers.length)];
    }

    static getRandomPhoneNumber(ddd: string = '') {
        const numbers = environment.contacts.phone;
        if (environment.changeContactByGeolocation) {
            const number = numbers.find((n) => n.number.includes(ddd));
            return number ?? numbers[0];
        }
        return numbers[Math.floor(Math.random() * numbers.length)];
    }
}