import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  isBrowser!: boolean

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId)
    console.log(this.isBrowser);
  }

  setItem(key: string, value: string): void {
    if (this.isBrowser) {
      window.localStorage?.setItem(key, value);
    }
  }

  getItem(key: string): string | null {
    if (this.isBrowser) {
      return window.localStorage?.getItem(key);
    }

    return null;
  }

  removeItem(key: string): void {
    if (this.isBrowser) {
      window.localStorage?.removeItem(key);
    }
  }
}