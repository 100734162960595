<div class="lab-container">
    <div class="lab-header-featured-list">
        <div class="lab-header-featured-list-item" *ngFor="let feature of data">
            <div class="lab-header-featured-list-item-icon">
                <span class="material-symbols-outlined">
                    {{ feature.icon}}
                </span>
            </div>
            <div class="lab-header-featured-list-item-content">
                <h3>{{ feature.title }}</h3>
                <p>{{ feature.description }}</p>
            </div>
        </div>
    </div>
</div>