import { Component, Input } from '@angular/core';
import { ButtonComponent, ButtonLinkData } from '../button/button.component';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { FloatingContactWhatsappListComponent } from './floating-contact-whatsapp-list/floating-contact-whatsapp-list.component';
import { ContactHelper } from '../../utils/contact-helper';
import { RegionService } from '../../services/region.service';

@Component({
  selector: 'app-floating-contact',
  standalone: true,
  imports: [ButtonComponent, CommonModule, FloatingContactWhatsappListComponent],
  providers: [RegionService],
  templateUrl: './floating-contact.component.html',
  styleUrl: './floating-contact.component.scss'
})
export class FloatingContactComponent {

  @Input() regions: any = [];

  contactPhoneVariation: string = 'primary';
  isActive: boolean = true;
  isWhatsappListActive: boolean = false;

  constructor(readonly regionService: RegionService) { }

  ngOnInit() {
    if (environment.theme == 'samsung') {
      this.contactPhoneVariation = 'secondary';
    }

    // setTimeout(() => {
    //   this.isActive = true;

    //   setTimeout(() => {
    //     this.isActive = false;
    //   }, 10000)

    // }, 4000);
  }

  openPhone(): ButtonLinkData {
    return {
      url: ContactHelper.getRandomPhoneNumberString(this.regionService.getCurrentDDD()).url,
      target: '_self',
    };
  }
  
  toggleWhatsappListActive() {
    this.isWhatsappListActive = !this.isWhatsappListActive;
    this.isActive = false;
  }

}
