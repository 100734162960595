import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type ButtonLinkData = {
  url: string;
  target: string;
}

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {

  @Input() label: string = '';
  @Input() id: string = '';
  @Input() href!: ButtonLinkData;
  @Input() borderType: string = '';
  @Input() variation: string = '';
  @Input() icon!: string;
  @Input() iconType: string  = 'material' // material | font-awesome;
  @Input() size: string = '';
  @Input() loading: boolean = false;
  @Output() action = new EventEmitter<Event>();

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
  }

  formatIcon(icon: string) {
    return icon.split('-')  as IconProp;
  }

  onClick(event: Event) {
    this.action.emit(event);

  }
}
