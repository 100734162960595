<div class="lab-schedule" id="agendamento">
  <div class="lab-schedule-header">
    <h2>{{ data.title }}</h2>
    <small>{{ data.subtitle }}</small>
  </div>
  <div class="lab-schedule-content">
    <form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()">
      <ng-container *ngIf="schedulingStep == 1">
        <div class="lab-schedule-content-heading">
          <span>
            <h2>Agendar visita técnica</h2>
            <small>Agende uma visita gratuitamente, sem taxas.</small>
          </span>
        </div>
        <mat-form-field>
          <mat-label>Seu nome</mat-label>
          <input matInput placeholder="Seu nome" formControlName="name">
          <mat-error *ngIf="scheduleForm.get('name')?.hasError('required') && scheduleForm.get('name')?.touched">
            Nome é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Telefone</mat-label>
          <input type="text" matInput mask="(99) 9 9999-9999" placeholder="Telefone" formControlName="phone">
          <mat-error *ngIf="scheduleForm.get('phone')?.hasError('required') && scheduleForm.get('phone')?.touched">
            Telefone é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="E-mail" formControlName="email">
          <mat-error *ngIf="scheduleForm.get('email')?.hasError('required') && scheduleForm.get('email')?.touched">
            E-mail é obrigatório
          </mat-error>
          <mat-error *ngIf="scheduleForm.get('email')?.hasError('email') && scheduleForm.get('email')?.touched">
            E-mail inválido
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Selecione um produto</mat-label>
          <mat-select formControlName="product">
            <mat-option *ngFor="let product of products" [value]="product.value">{{product.label}}</mat-option>
          </mat-select>
          <mat-error *ngIf="scheduleForm.get('product')?.hasError('required') && scheduleForm.get('product')?.touched">
            Produto é obrigatório
          </mat-error>
        </mat-form-field>

        <app-button (action)="startSchedule()" [loading]="loading" label="Iniciar agendamento" borderType="rounded"
          variation="primary"></app-button>
      </ng-container>

      <ng-container *ngIf="schedulingStep == 2">
        <div class="lab-schedule-content-heading">
          <span>
            <h2>Endereço</h2>
            <small>Informe os dados do seu endereço</small>
          </span>
          <span>
            <app-button (action)="previousStep()" icon="arrow_back" label="" borderType="pill" size="small"
              variation="secondary"></app-button>
          </span>
        </div>

        <mat-form-field>
          <mat-label>Seu CEP</mat-label>
          <input matInput mask="99999-999" placeholder="Seu CEP" formControlName="zipcode">
          <mat-error *ngIf="scheduleForm.get('zipcode')?.hasError('required') && scheduleForm.get('zipcode')?.touched">
            CEP é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Endereço</mat-label>
          <input matInput placeholder="Endereço" formControlName="address">
          <mat-error *ngIf="scheduleForm.get('address')?.hasError('required') && scheduleForm.get('address')?.touched">
            Endereço é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Número</mat-label>
          <input matInput placeholder="Número" formControlName="number">
          <mat-error *ngIf="scheduleForm.get('number')?.hasError('required') && scheduleForm.get('number')?.touched">
            Número é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Complemento</mat-label>
          <input matInput placeholder="Complemento" formControlName="complement">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Bairro</mat-label>
          <input matInput placeholder="Bairro" formControlName="neighborhood">
          <mat-error
            *ngIf="scheduleForm.get('neighborhood')?.hasError('required') && scheduleForm.get('neighborhood')?.touched">
            Bairro é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Cidade / Município</mat-label>
          <input matInput placeholder="Cidade / Município" formControlName="city">
          <mat-error *ngIf="scheduleForm.get('city')?.hasError('required') && scheduleForm.get('city')?.touched">
            Cidade é obrigatório
          </mat-error>
        </mat-form-field>

        <app-button (action)="nextStep()" label="Continuar (2/4)" borderType="rounded" variation="primary"></app-button>
      </ng-container>

      <ng-container *ngIf="schedulingStep == 3">
        <div class="lab-schedule-content-heading">
          <span>
            <h2>Defeito apresentado</h2>
            <small>Selecione um defeito apresentado</small>
          </span>

          <span>
            <app-button (action)="previousStep()" icon="arrow_back" label="" borderType="pill" size="small"
              variation="secondary"></app-button>
          </span>
        </div>

        <mat-form-field>
          <mat-label>Selecione um defeito</mat-label>
          <mat-select formControlName="defect">
            <mat-option *ngFor="let defect of defects" [value]="defect.value">{{defect.label}}</mat-option>
          </mat-select>
          <mat-error *ngIf="scheduleForm.get('defect')?.hasError('required') && scheduleForm.get('defect')?.touched">
            Defeito é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-radio-group aria-label="Seu produto possui mais de 1 ano de uso?" formControlName="warranty">
          <mat-label>Seu produto possui mais de 1 ano de uso?</mat-label>
          <mat-radio-button value="Não">Sim</mat-radio-button>
          <mat-radio-button value="Sim">Não</mat-radio-button>
        </mat-radio-group>

        <app-button (action)="nextStep()" label="Continuar (3/4)" borderType="rounded" variation="primary"></app-button>
      </ng-container>

      <ng-container *ngIf="schedulingStep == 4">
        <div class="lab-schedule-content-heading">
          <span>
            <h2>Data e período de visita</h2>
            <small>Selecione uma data e período para a sua visita técnica</small>
          </span>
          <span>
            <app-button (action)="previousStep()" icon="arrow_back" label="" borderType="pill" size="small"
              variation="secondary"></app-button>
          </span>
        </div>

        <mat-form-field>
          <mat-label>Selecione um período</mat-label>
          <mat-select formControlName="period">
            <mat-option *ngFor="let period of periods" [value]="period.value">{{period.label}}</mat-option>
          </mat-select>
          <mat-error *ngIf="scheduleForm.get('period')?.hasError('required') && scheduleForm.get('period')?.touched">
            Período é obrigatório
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Data de visita</mat-label>
          <input matInput [matDatepickerFilter]="filter" [matDatepicker]="picker" formControlName="visitDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="scheduleForm.get('visitDate')?.hasError('required') && scheduleForm.get('visitDate')?.touched">
            Data de visita é obrigatória
          </mat-error>
        </mat-form-field>

        <app-button (action)="onSubmit()" [loading]="loading" label="Concluir agendamento" borderType="rounded"
          variation="primary"></app-button>
      </ng-container>

      <ng-container *ngIf="schedulingStep == 5">
        <div class="lab-schedule-content-heading">
          <span>
            <h2>Agendamento concluído</h2>
            <small>Um de nossos técnicos realizará a visita técnica na data solicitada</small>
          </span>
        </div>

        <div class="lab-schedule-content-end">
          <h3>Protocolo de agendamento:</h3>
          <div>
            <span>
              {{ currentSchedule.agendamento_id }}
            </span>
          </div>
        </div>
      </ng-container>
      <small>Ao iniciar o seu agendamento você concorda com <a href="/politica-de-privacidade">Política de privacidade.</a></small>
    </form>
  </div>
</div>