import { Component } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {

  constructor(readonly themeService: ThemeService) {}

  public data:any = {
    title: `Política de Privacidade`,
    backgroundImage: `url("/assets/theme/${this.themeService.getTheme()}/background_image.webp")`,
  }
}
