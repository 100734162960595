import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonComponent, ButtonLinkData } from '../../../../shared/components/button/button.component';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { ChatbotService } from '../../../../shared/services/chatbot.service';
import { MatDialog } from '@angular/material/dialog';
import { SearchZipcodeComponent } from '../../../../shared/components/search-zipcode/search-zipcode.component';
import { ScrollHelper } from '../../../../shared/utils/scroll-helper';
import { ContactHelper } from '../../../../shared/utils/contact-helper';
import { RegionService } from '../../../../shared/services/region.service';

@Component({
  selector: 'app-featured',
  standalone: true,
  imports: [
    ButtonComponent, 
    CommonModule
  ],
  providers: [RegionService],
  templateUrl: './featured.component.html',
  styleUrl: './featured.component.scss'
})
export class FeaturedComponent implements OnChanges{
  readonly dialog = inject(MatDialog);

  @Input() regions: any = [];

  @Output() onSearchRegion = new EventEmitter<string>();

  constructor(readonly chatbotService: ChatbotService, readonly regionService: RegionService) {
  }

  public data: any = {
    brand: environment.content.brand,
    product: environment.content.product,
    title: `${environment.content.term} ${ environment.content.product } ${ environment.content.brand }  ${ environment.content.region.name }`,
    description: `Agende o orçamento para sua ${environment.content.product} ${environment.content.brand} em ${environment.content.region.name}`,
    features: [
      'Lorem ipsum dolor sit amet consectetur. Elementum nisl duis tortor sed.',
      'Lorem ipsum dolor sit amet consectetur. Elementum nisl duis tortor sed.',
      'Lorem ipsum dolor sit amet consectetur. Elementum nisl duis tortor sed.',
      'Lorem ipsum dolor sit amet consectetur. Elementum nisl duis tortor sed.',
      'Lorem ipsum dolor sit amet consectetur. Elementum nisl duis tortor sed.',
      'Lorem ipsum dolor sit amet consectetur. Elementum nisl duis tortor sed.',
    ],
    actions: [
      {
        label: 'Agendar visita',
        variation: 'primary',
        borderType: 'pill',
        url: ''
      },
    ],
    fastSupport: {
      title: 'Atendimento rápido',
      description: 'Atendimento em até 24 horas, sem taxa de visita técnica',
      supports: [
        {
          title: 'Localizar Assistência Técnica',
          description: 'Encontre a assistência técnica mais próxima de você',
          icon: 'home_pin',
          action: {
            label: 'Localizar',
            execute: (event: Event) => {
              const dialogRef = this.dialog.open(SearchZipcodeComponent);
  
              dialogRef.componentInstance.onSearchRegion.subscribe((data: any) => {
                dialogRef.close();
                setTimeout(() => {
                  this.onSearchRegion.emit(data)
                  ScrollHelper.toSection(event, 'regiao-atendimento');
                }, 500);
              });
            }
          }
        },
        {
          title: 'Chat',
          description: 'Obtenha suporte de nossos assistentes virtuais',
          featured: 'Disponível 24 horas por dia.',
          icon: 'chat_bubble',
          action: {
            label: 'Iniciar Chat',
            href: this.openWhatsApp()
          }
        },
        {
          title: 'Central de Atendimento',
          description: 'Fale com nossa central de atendimento',
          icon: 'support_agent',
          action: {
            label: 'Suporte via telefone',
            href: this.openPhone()
          }
        },
        {
          title: 'Whatsapp',
          description: 'Fale conosco via Whatsapp',
          featured: 'Disponível 24 horas por dia.',
          icon: 'home_pin',
          action: {
            label: 'Suporte via Whatsapp',
            href: this.openWhatsApp()
          }
        }
      ],
    }
  };

  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['regions']) {
      this.data.title = `${environment.content.term} ${ environment.content.product } ${ environment.content.brand } ${this.regions[0]?.localidade}`;
      this.data.description = `Agende o orçamento para sua ${environment.content.product} ${environment.content.brand} em ${this.regions[0]?.localidade}`;
    }
  }

  scrollToScheduling(event: Event) {
    ScrollHelper.toSection(event, 'agendamento');
  }

  openWhatsApp(): ButtonLinkData {
    return {
      url: ContactHelper.getRandomWhatsAppNumberString(this.regionService.getCurrentDDD()).url,
      target: '_blank'
    }
  }

  openPhone(): ButtonLinkData {
    return {
      url: ContactHelper.getRandomPhoneNumberString(this.regionService.getCurrentDDD()).url,
      target: '_self'
    }
  }
}
