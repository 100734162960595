<div class="lab-featured" id="assistencia-tecnica">
    <div class="lab-container">
        <div class="lab-featured-fast-support">
            <div class="lab-featured-fast-support-header">
                <h3>{{ data.fastSupport.title }}</h3>
                <p>{{ data.fastSupport.description }}</p>
            </div>
            <div class="lab-featured-fast-support-content">
                <div class="lab-featured-fast-support-list">
                    <div class="lab-featured-fast-support-list-item" *ngFor="let support of data.fastSupport.supports">
                        <div class="lab-featured-fast-support-list-item-content">
                            <div class="lab-featured-fast-support-list-item-header">
                                <h4>{{ support.title }}</h4>
                                <p>{{ support.description }}</p>
                                <div class="lab-featured-fast-support-list-item-header-featured">
                                    <strong>
                                        {{ support.featured ?? ''}}
                                    </strong>
                                </div>
                            </div>
                            <div class="lab-featured-fast-support-list-item-icon">
                                <span class="material-symbols-outlined">
                                    {{ support.icon }}
                                </span>
                            </div>
                        </div>
                        <app-button borderType="rounded" (action)="support.action.execute()" variation="primary" label="{{ support.action.label }}"></app-button>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="regions.length > 0">
            <div class="lab-featured-header" id="regiao-atendimento">
                <h1>{{ data.title }}</h1>
                <h2>{{ data.description }}</h2>
            </div>
            <div class="lab-featured-technical-list">
                <ng-container *ngFor="let region of regions; let i = index">
                    <div class="lab-featured-technical-list-item" [ngClass]="{ 'featured': i === 0 }">
                        <div class="lab-featured-technical-list-item-content">
                            <span class="lab-featured-technical-list-item-content-label">{{ i == 0 ? 'Em sua região' : 'Próximo de você' }}</span>
                            <h2>Assistência Técnica {{ data.product }} {{ data.brand }} {{ region.bairro }}</h2>
                            <p>Conserto de {{ data.product }}  {{ data.brand }} com garantia e peças originais. Atendimento em {{ region.bairro }} e região.</p>
                            <ul class="lab-featured-technical-list-item-content-feature-list">
                                <li class="lab-featured-technical-list-item-content-feature-list-item">
                                    <span>Visita grátis</span>
                                </li>
                                <li class="lab-featured-technical-list-item-content-feature-list-item">
                                    <span>Peças originais</span>
                                </li>
                                <li class="lab-featured-technical-list-item-content-feature-list-item">
                                    <span>1 Ano de garantia</span>
                                </li>
                            </ul>
                        </div>
                        <div class="lab-featured-technical-list-item-actions">
                            <app-button borderType="rounded" (action)="scrollToScheduling($event)" variation="primary" label="Agendar visita técnica"></app-button>
                            <app-button borderType="rounded" (action)="openWhatsApp($event)" icon="fab-whatsapp" iconType="font-awesome" variation="whatsapp-outlined" label=""></app-button>
                            <app-button borderType="rounded" (action)="openPhone($event)" icon="phone" variation="secondary" label=""></app-button>

                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="lab-featured-footer lab-flex lab-center">
                <div>
                    <app-button borderType="rounded" variation="secondary" label="mais regiões"></app-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>