<div class="lab-header" [ngStyle]="{'background-image': data.backgroundImage}">
    <div class="lab-header-overlay">
        <div class="lab-container lab-flex lab-justify-content-center">
            <div class="lab-header-content lab-header-content-full-with-localization">
                <div class="lab-header-content-info">
                    <h1>{{ data.title }}</h1>
                </div>
            </div>
        </div>
    </div>
</div>