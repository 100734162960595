import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'politica-de-privacidade', component: PrivacyPolicyComponent},
    { path: '', redirectTo: '', pathMatch: 'full' }, // Rota padrão
    { path: '**', redirectTo: '' } // Rota para lidar com URLs desconhecidas
];
