export class ScrollHelper {
    static toSection(event: Event, sectionId: string) {
      if (event) {
        event.preventDefault();
      }
        const element = document.getElementById(sectionId);
        if (element) {

          element.style.marginTop = `-150px`;

          element.scrollIntoView({ behavior: 'smooth' });

          setTimeout(() => {
            element.style.marginTop = '';
          }, 500); 
        }
    }
}