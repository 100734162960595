import { Component } from '@angular/core';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { FeaturedComponent } from './components/featured/featured.component';
import { MainComponent } from './components/main/main.component';
import { MiddleCallToActionComponent } from './components/middle-call-to-action/middle-call-to-action.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { FloatingContactComponent } from '../../shared/components/floating-contact/floating-contact.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent, 
    FeaturedComponent, 
    MainComponent, 
    MiddleCallToActionComponent,
    FaqComponent, 
    FooterComponent,
    FloatingContactComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  regions: any = [];
  onGetRegion(event: any) {
    this.regions = event;
  }
}
