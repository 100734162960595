<header id="header">
    <app-navbar (onSearchRegion)="updateRegions($event)"></app-navbar>
    <div class="lab-header-call-to-action">
        <div class="lab-container">
            <div class="lab-header-call-to-action-content lab-flex lab-justify-content-between lab-align-items-center">
                <strong>{{ data.top.title }}</strong>
                <div class="lab-header-call-to-action-contact-list lab-flex">
                    <ng-container *ngFor="let action of data.top.actions">
                        <app-button icon="{{ action.icon ?? '' }}" (action)="action.action($event)" borderType="{{ action.borderType }}" size="{{ action.size }}" variation="{{ action.variation }}" label="{{ action.label }}"></app-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="lab-header">
        <div class="lab-header-overlay">
            <div class="lab-container">
                <div class="lab-header-content">
                    <div class="lab-header-content-info">
                        <h1>{{ data.title }}</h1>
                        <p>{{ data.description }}</p>
                        <ng-container *ngFor="let action of data.actions">
                            <app-button borderType="{{ action.borderType }}" variation="{{ action.variation }}" label="{{ action.label }}"></app-button>
                        </ng-container>
                    </div>
                    <div>
                        <app-schedule-form></app-schedule-form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="lab-header" [ngStyle]="{'background-image': data.backgroundImage}">
        <div class="lab-header-overlay">
            <div class="lab-container lab-flex lab-justify-content-center">
                <div class="lab-header-content lab-header-content-full-with-localization">
                    <div class="lab-header-content-info">
                        <h1>{{ data.title }}</h1>
                        <h2>{{ data.description }}</h2>
                        <form [formGroup]="regionForm">
                            <mat-form-field>
                                <mat-label>Digite o seu CEP</mat-label>
                                <input matInput mask="99999-999" (keyup)="onKeyUp($event, regionForm.get('zipcode')?.value)" placeholder="Digite o seu CEP" formControlName="zipcode">
                            </mat-form-field>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-featured></app-featured>
</header>