export const environment = {
    apiUrl: 'https://watesistema.com.br/api/v1',
    zipcodeAPIUrl: 'https://viacep.com.br/ws/',
    theme: 'samsung',
    title: 'Assistência Técnica Samsung',
    changeContactByGeolocation: true,
    showAllWhatsAppNumbers: false,
    fantasy_name: 'LavTec Assistência Técnica',
    google: {
        tagmanager: {
            key: ''
        }
    },
    idCompany: '5',
    content: {
        region: {
            zipcode: '01153-000',
            name: 'São Paulo'
        },
        brand: 'Samsung',
        product: 'Lava e Seca',
        term: 'Assistência Técnica'
    },

    contacts: {
        whatsapp: [
            {
                name: 'Central de Atendimento',
                number: '551140405715',
                text: '(11) 4040-5715',
                message: 'Olá, gostaria de saber mais sobre os serviços de assistência técnica.'
            },
            {
                name: 'Central de Atendimento',
                number: '551235002597',
                text: '(12) 3500-2597',
                message: 'Olá, gostaria de saber mais sobre os serviços de assistência técnica.'
            },
            {
                name: 'Central de Atendimento',
                number: '551535007339',
                text: '(15) 3500-7339',
                message: 'Olá, gostaria de saber mais sobre os serviços de assistência técnica.'
            },
            {
                name: 'Central de Atendimento',
                number: '55194040-4472',
                text: '(19) 4040-4472',
                message: 'Olá, gostaria de saber mais sobre os serviços de assistência técnica.'
            },
        ],
        phone: [
            {
                number: '+55114040-5715',
                text: '(11) 4040-5715'
            },
            {
                number: '+55123500-2597',
                text: '(12) 3500-2597'
            },
            {
                number: '+55153500-7339',
                text: '(15) 3500-7339'
            },
            {
                number: '+55194040-4472',
                text: '(19) 4040-4472'
            },
        ]
    }
};

