<footer class="lab-footer">
    <div class="lab-container">
        <div class="lab-footer-cta" *ngIf="data.callToAction.enabled">
            <div class="lab-footer-cta-content">
                <h3>{{ data.callToAction.title }}</h3>
                <h4>{{ data.callToAction.description }}</h4>
            </div>
            <div class="lab-footer-cta-action">
                <app-button variation="secondary" borderType="pill" label="Central de atendimento"></app-button>
            </div>
        </div>
        <div class="lab-footer-content">
            <div class="lab-footer-logo">
                <img src="{{ data.logo.image }}" alt="{{ data.logo.text }}">
                <small>{{ data.description }}</small>
            </div>
            <div class="lab-footer-regions" *ngIf="regions.length > 0">
                <h4>Mais regiões</h4>
                <ul class="lab-footer-regions-list">
                    <li class="lab-footer-regions-list-item" *ngFor="let region of regions">
                        <a href="#">{{ data.term }} {{ data.brand }} {{ region.bairro }}</a>
                    </li>
                </ul>
            </div>
            <div class="lab-footer-contact">
                <h4>Central de Atendimento</h4>
                <ul class="lab-footer-contact-list">
                    <li class="lab-footer-contact-list-item" *ngFor="let contact of data.contactLinks">
                        <app-button 
                            onkeypress=""
                            (click)="contact.action($event)"
                            variation="{{ contact.variation }}" 
                            icon="{{ contact.icon }}" 
                            iconType="{{ contact.iconType }}" 
                            borderType="pill" 
                            label="{{ contact.text }}">
                        </app-button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="lab-footer-copyright">
            <span>{{ data.copyright }} - Todos os diretos reservados</span>
        </div>
    </div>
</footer>