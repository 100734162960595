import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

interface ScheduleForm {
  idSchedule: string;
  name: string;
  phone: string;
  email: string;
  product: string;
  zipcode: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  defect: string;
  visitDate: Date;
  period: string;
  warranty: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleFormService {

  constructor(readonly http: HttpClient) {}

  saveSchedule(params: ScheduleForm): Observable<any> {
    const formData = new FormData();

    formData.append('nome', params.name);
    formData.append('telefone', params.phone);
    formData.append('email', params.email);
    formData.append('produto', params.product);
    formData.append('marca', environment.content.brand);
    formData.append('origem', window.location.href);
    formData.append('id_empresa', environment.idCompany);

    return this.http.post(environment.apiUrl + '/agendamento/salvar', formData);
  }

  generateSchedule(params: ScheduleForm): Observable<any> {

    const formData = new FormData();

    formData.append('agendamento_id', params.idSchedule);
    formData.append('nome', params.name);
    formData.append('telefone', params.phone);
    formData.append('email', params.email);
    formData.append('produto', params.product);
    formData.append('cep', params.zipcode);
    formData.append('endereco', params.address);
    formData.append('numero', params.number);


    let complement = params.complement;
    if (!params.complement) {
      complement = 'N/A';
    }

    formData.append('complemento', complement);

    formData.append('bairro', params.neighborhood);
    formData.append('cidade', params.city);
    formData.append('estado', params.state);
    formData.append('defeito', params.defect);
    formData.append('periodo', params.period);
    formData.append('garantia', params.warranty);
    formData.append('marca', environment.content.brand);
    formData.append('origem', window.location.href);
    formData.append('id_empresa', environment.idCompany);


    const formattedVisitDate = this.formatDate(params.visitDate.toString());
    formData.append('data', formattedVisitDate);

    return this.http.post<any>(environment.apiUrl + '/agendamento/editar', formData);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
