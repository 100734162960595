<div class="lab-floating-contact-whatsapp-list" [ngClass]="{'open' : open}">
    <div class="lab-floating-contact-whatsapp-list-header">
        <div class="lab-floating-contact-whatsapp-list-header-text">
            Central de Atendimento via Whatsapp
            <small>Fale com um de nossos especialistas 24 horas</small>
        </div>
    </div>
    <div class="lab-floating-contact-whatsapp-list-content">
        <ul class="lab-floating-contact-whatsapp-list-content-list">
            <li *ngFor="let whatsapp of whatsappNumbers">
                <a class="lab-floating-contact-whatsapp-list-content-list-item" [href]="openWhatsapp(whatsapp).url" [target]="openWhatsapp(whatsapp).target">
                    <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
                    <div class="lab-floating-contact-whatsapp-list-content-list-item-text">
                        <strong>{{ whatsapp.text }}</strong>
                        <small>Agende uma visita agora mesmo!</small>
                    </div>
                    <div class="lab-floating-contact-whatsapp-list-content-list-item-avatar">
                        <img src="https://static.getbutton.io/img/flag.jpg?v=1" alt="">
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div>