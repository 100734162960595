import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from './shared/services/theme.service';
import { environment } from '../environments/environment';
import { ChatbotService } from './shared/services/chatbot.service';
import { Title } from '@angular/platform-browser';
import { GoogleTagManagerService } from './shared/services/google-tag-manager.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'frontend';

  constructor(
    readonly themeService: ThemeService,
    readonly chatbotService: ChatbotService,
    readonly googleTagManagerService: GoogleTagManagerService,
    readonly titleService: Title
  ) {}

  ngOnInit() {
    this.themeService.setTheme(environment.theme);
    this.titleService.setTitle(environment.title);

    // this.chatbotService.initialize();
    this.googleTagManagerService.insertGtmScript();
  }

}
