import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SearchZipcodeComponent } from '../search-zipcode/search-zipcode.component';
import { ThemeService } from '../../services/theme.service';
import { ScrollHelper } from '../../utils/scroll-helper';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatDialogModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  readonly dialog = inject(MatDialog);
  isMenuActive: boolean = false;

  @Input() showOnlyGoBack: boolean = false;

  @Output() onSearchRegion = new EventEmitter<string>();

  constructor(readonly themeService: ThemeService) { }

  public data: any = {
    logo: {
      type: 'image',
      text: 'PrimeServices',
      image: `assets/theme/${this.themeService.getTheme()}/logo.webp`
    },
    contactActions: {
      enabled: true,
      actions: [
        {
          label: 'Central de atendimento',
          variation: 'default',
          borderType: 'pill',
          size: 'small',
          url: '',
          action: (event: Event) => {
            this.scrollToSection(event, 'regiao-atendimento')
          }
        },
        {
          label: '',
          icon: 'location_on',
          variation: 'default',
          borderType: 'pill',
          size: 'small',
          url: '',
          action: (event: Event) => {
            const dialogRef = this.dialog.open(SearchZipcodeComponent);

            dialogRef.componentInstance.onSearchRegion.subscribe((data: any) => {
              dialogRef.close();
              setTimeout(() => {
                this.onSearchRegion.emit(data)
              }, 500);
            });
          }
        },
      ]
    }
  }

  scrollToSection(event: Event, sectionId: string) {
    ScrollHelper.toSection(event, sectionId);
    this.isMenuActive = false;
  }

  toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
  }

  goToHome() {
    window.location.href = '/';
  }
}
