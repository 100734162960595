<div class="lab-middle-call-to-action" style="background-image: url('{{ data.backgroundImage }}');">
    <div class="lab-middle-call-to-action-overlay">
        <div class="lab-container">
            <div class="lab-middle-call-to-action-content">
                <div>
                    <app-schedule-form></app-schedule-form>
                </div>
                <div class="text">
                    <h3>{{ data.title }}</h3>
                    <p>{{ data.description }}</p>
                    <div class="lab-middle-call-to-action-content-actions">
                        <ng-container *ngFor="let action of data.actions">
                            <app-button (action)="action.action($event)" borderType="{{ action.borderType }}" variation="{{ action.variation }}" label="{{ action.label }}"></app-button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lab-middle-support-fulltime" id="chat">
    <div class="lab-container">
        <div class="lab-middle-support-fulltime-content">
            <h2>Atendimento 24 horas via WhatsApp</h2>
            <p>Fale conosco neste exato momento</p>
        </div>
        <div class="lab-middle-support-fulltime-action">
            <app-button borderType="rounded" (action)="openWhatsApp()" variation="primary" icon="fab-whatsapp" iconType="font-awesome" label="Fale via WhatsApp"></app-button>
        </div>
    </div>
</div>