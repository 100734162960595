import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

declare var BotmanWidget: any;

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  readonly chatbot: any;
  constructor(@Inject(DOCUMENT) readonly document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }

  
  loadBotmanWidgetScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!isPlatformBrowser(this.platformId)) {
        resolve();
        return;
      }

      if (typeof BotmanWidget !== 'undefined') {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/js/widget.js';
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load BotmanWidget script'));
      document.head.appendChild(script);
    });
  }

  initialize() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.loadBotmanWidgetScript().then(() => {
      window.botmanWidget = {
        frameEndpoint: 'https://chatbot-primeservices-api.inovlab.com.br/botman/chat',
        aboutLink: '#',
        aboutText: 'Desenvolvido por InovLab',
        title: 'Atendimento Online',
        // introMessage: 'Olá! Como posso te ajudar?',
        placeholderText: 'Digite sua mensagem...',
        bubbleBackground: '#0c0c0c', 
        mainColor: '#0c0c0c',
        headerTextColor: '#fff',
      };

    }).catch(error => {
      console.error('Error loading BotmanWidget script:', error);
    });
  }

  open() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    setTimeout(() => {
      this.loadBotmanWidgetScript().then(() => {
        if (window.botmanChatWidget) {
          window.botmanChatWidget.open();
        } else {
          console.error('BotmanChatWidget is not available');
        }
      }).catch(error => {
        console.error('Error loading BotmanWidget script:', error);
      });
    }, 5000);
  }
}
