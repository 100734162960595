import { Component, Input, SimpleChanges } from '@angular/core';
import { ButtonComponent, ButtonLinkData } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { ThemeService } from '../../services/theme.service';
import { environment } from '../../../../environments/environment';
import { ContactHelper } from '../../utils/contact-helper';
import { RegionService } from '../../services/region.service';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [ButtonComponent, CommonModule, HttpClientModule],
  providers: [RegionService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  @Input() regions: any = [];
  contact!: ButtonLinkData;
  whatsapp!: ButtonLinkData;
  data!: any;

  constructor(readonly themeService: ThemeService, readonly regionService: RegionService) { }

  ngOnInit() {
    this.loadFooter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['regions']) {
      this.loadFooter();
    }
  }

  loadFooter() {
    const contact = ContactHelper.getRandomPhoneNumberString(this.regionService.getCurrentDDD());
    const whatsapp =  ContactHelper.getRandomWhatsAppNumberString(this.regionService.getCurrentDDD());
    
    this.contact = {
      url: contact.url,
      target: '_self'
    };

    this.whatsapp = {
      url: whatsapp.url,
      target: '_blank',
    }

    this.data = {
      term: environment.content.term,
      brand: environment.content.brand,
      product: environment.content.product,
      copyright: environment.fantasy_name,
      logo: {
        type: 'image',
        text: environment.fantasy_name,
        image: `assets/theme/${this.themeService.getTheme()}/logo.webp`
      },
      description: `${environment.title} - ${environment.content.term} ${environment.content.product} ${environment.content.brand} ${environment.content.region.name}`,
      contactLinks: [
        {
          text: contact.text,
          icon: 'phone',
          iconType: 'material',
          variation: 'primary',
          href: this.contact
        },
        {
          text: whatsapp.text,
          icon: 'fab-whatsapp',
          iconType: 'font-awesome',
          variation: 'secondary',
          href: this.whatsapp,
        },
      ],
      regionsLinks: {},
      callToAction: {
        enabled: true,
        title: 'Ainda com dúvidas?',
        description: 'Fale com nossa central de atendimento',
        urlAction: '/'
      }
    };
  }
}
