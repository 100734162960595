import { Component } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { CommonModule } from '@angular/common';
import { ScheduleFormComponent } from '../../../../shared/components/schedule-form/schedule-form.component';
import { ThemeService } from '../../../../shared/services/theme.service';
import { ScrollHelper } from '../../../../shared/utils/scroll-helper';
import { environment } from '../../../../../environments/environment';
import { ContactHelper } from '../../../../shared/utils/contact-helper';
import { RegionService } from '../../../../shared/services/region.service';

@Component({
  selector: 'app-middle-call-to-action',
  standalone: true,
  imports: [ButtonComponent, CommonModule, ScheduleFormComponent],
  providers: [RegionService],
  templateUrl: './middle-call-to-action.component.html',
  styleUrl: './middle-call-to-action.component.scss'
})
export class MiddleCallToActionComponent {

  constructor(readonly themeService: ThemeService, readonly regionService: RegionService) {}

  public data: any = {
    title: `Atendimento em toda região de ${environment.content.region.name}`,
    description: 'Seja atendido em menos de 24 horas, agende grátis.',
    backgroundImage: `assets/theme/${this.themeService.getTheme()}/bg_middle_call_to_action.webp`,
    actions: [
      {
        label: 'Falar com a central de atendimento',
        variation: 'secondary',
        borderType: 'pill',
        action: (event: Event) => {
          ScrollHelper.toSection(event, 'regiao-atendimento')
        }
      }
    ]
  };

  openWhatsApp() {
    let whatsapp = ContactHelper.getRandomWhatsAppNumber(this.regionService.getCurrentDDD());
    open(`https://wa.me/${whatsapp.number}?text=${whatsapp.message}`, '_blank');
  }
}
