import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-featured',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './featured.component.html',
  styleUrl: './featured.component.scss'
})
export class FeaturedComponent {
  public data:any = [
    {
      title: 'Peças Originais',
      icon: 'build',
      description: 'Serviços com 1 ano de garantia e peças originais Samsung',
    },
    {
      title: 'Atendimento em até 24 horas',
      icon: 'timer',
      description: 'Atendimento em até 24 horas, sem taxa de visita técnica',
    },
    {
      title: 'Profissionais Certificados',
      icon: 'verified_user',
      description: 'Profissionais certificados e treinados pela Samsung',
    },
  ];
}
