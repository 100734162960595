<div class="lab-faq" id="faq">
    <div class="lab-container">
       
        <div class="lab-faq-content">
            <div class="lab-faq-content-image"></div>
            <div class="lab-faq-content-questions">
                <div class="lab-faq-content-questions-header">
                    <h2>Perguntas Frequentes</h2>
                    <p>As perguntas mais frequentes sobre {{ data.product }} {{ data.brand }}</p>
                </div>
                <div class="lab-faq-content-questions-list">
                    <ng-container *ngFor="let faq of faqList">
                        <div class="lab-faq-content-questions-list-item" [class.opened]="faq.opened" (click)="toggleFaq(faq)">
                            <div class="lab-faq-content-questions-list-item-title">
                                <ng-container>
                                    <strong>{{ faq.title }}</strong>
                                    <ng-container *ngIf="faq.opened"><img src="assets/icons/minus.svg" alt=""></ng-container>
                                    <ng-container *ngIf="!faq.opened"><img src="assets/icons/plus.svg" alt=""></ng-container>
                                </ng-container>
                            </div>
                            <div class="lab-faq-content-questions-list-item-content">
                                <p>{{ faq.content }}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>