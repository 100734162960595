import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component';
import { ButtonComponent } from '../button/button.component';
import { ScheduleFormComponent } from '../schedule-form/schedule-form.component';
import { FeaturedComponent } from './featured/featured.component';
import { CommonModule } from '@angular/common';
import { RegionService } from '../../services/region.service';
import { HttpClientModule } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ThemeService } from '../../services/theme.service';
import { environment } from '../../../../environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ScrollHelper } from '../../utils/scroll-helper';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NavbarComponent, 
    ButtonComponent, 
    ScheduleFormComponent, 
    FeaturedComponent, 
    CommonModule, 
    HttpClientModule, 
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective
  ],
  providers: [RegionService, provideNgxMask()],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  zipcode: FormControl = new FormControl();
  regionForm!: FormGroup;

  public data:any = {
    title: `Encontre uma Assistência Técnica ${environment.content.product} ${environment.content.brand} em sua região`,
    description: 'Atendimento em até 24 horas, sem taxa de visita técnica',
    backgroundImage: `url("/assets/theme/${this.themeService.getTheme()}/background_image.webp")`,
    top: {
      enabled: true,
      title: 'Agende sua visita técnica sem taxas',
      actions: [
        {
          label: 'Falar com especialista',
          variation: 'primary',
          borderType: 'pill',
          size: 'small',
          icon: 'chat_bubble',
          url: '',
          action: (event: Event) => {
            ScrollHelper.toSection(event, 'chat');
          }
        },
        {
          label: 'Agendar visita',
          variation: 'secondary',
          borderType: 'pill',
          size: 'small',
          url: '',
          action: (event: Event) => {
            ScrollHelper.toSection(event, 'agendamento');
          }
        },
      ]
    },
    actions: [
      {
        label: 'Agendar visita',
        variation: 'primary',
        borderType: 'pill',
        url: ''
      },
    ]
  }

  @Output() onSearchRegion = new EventEmitter<string>();
  
  constructor(readonly fb: FormBuilder, readonly regionService: RegionService, readonly themeService: ThemeService) { }
  
  ngOnInit() {
    this.regionService.search(environment.content.region.zipcode).subscribe((data: any) => {
      this.onSearchRegion.emit(data);
    });

    this.regionForm = this.fb.group({
      zipcode: ['', Validators.required],
    });

  }

  onActionSearchRegion() {
    this.regionService.search(this.zipcode.value).subscribe((data: any) => {
      this.onSearchRegion.emit(data);
    });
  }

  onKeyUp(event: Event, value: string) {
    if (value.length < 8) {
      return;
    }

    this.regionService.search(value).subscribe((data: any) => {
      this.onSearchRegion.emit(data);
      ScrollHelper.toSection(event, 'regiao-atendimento');
    });
  }

  updateRegions(data: any) {
    this.onSearchRegion.emit(data);
    const element = document.getElementById('regiao-atendimento');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
