import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegionService } from '../../services/region.service';
import { ThemeService } from '../../services/theme.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-search-zipcode',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule],
  templateUrl: './search-zipcode.component.html',
  styleUrl: './search-zipcode.component.scss',
  providers: [RegionService, ThemeService]
})
export class SearchZipcodeComponent {
  zipcode: FormControl = new FormControl();
  regionForm!: FormGroup;
  regions: any;

  @Output() onSearchRegion = new EventEmitter<string>();

  data: any = {
    title: `Buscar assistências para ${environment.content.product} ${environment.content.brand} em sua região`,
    subtitle: 'Digite o CEP de sua região para encontrar as assistências técnicas mais próximas de você'
  }

  constructor(readonly fb: FormBuilder, readonly regionService: RegionService, readonly themeService: ThemeService) { }

  ngOnInit() {
    this.regionForm = this.fb.group({
      zipcode: ['', Validators.required],
    });
  }

  scrollToSection(event: Event, sectionId: string) {
    event.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onKeyUp(event: Event, value: string) {
    if (value.length < 8) {
      return;
    }

    this.regionService.search(value).subscribe((data: any) => {
      this.onSearchRegion.emit(data);
    });
  }
}
