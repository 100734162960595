import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { LocalStorageService } from "./local-storage.service";

export const CURRENT_REGION = 'currentRegion';

@Injectable()
export class RegionService {

    regions: any = [];

    constructor(readonly http: HttpClient, readonly localStorageService: LocalStorageService) {}

    getRegion(zipcode: string): Observable<any> {
        let url = `${environment.zipcodeAPIUrl}${zipcode}/json/`;
        return this.http.get(url);
    }

    search(zipcode: string): Observable<any> {

        if (zipcode.length < 8) {
            return of(null);
        }

        let url = `${environment.zipcodeAPIUrl}${zipcode}/json/`;
        this.regions = [];
        return this.http.get(url).pipe(
            switchMap((dados: any) => {
                if (dados.erro) {
                    console.error('CEP inválido');
                    return of(null); // Return an observable with null to terminate the chain
                }

                this.regions.push(dados);

                return this.http.get(`https://viacep.com.br/ws/${dados.uf}/${dados.localidade}/${dados.localidade}/json/`);
            }),
            switchMap((dados: any) => {
                if (dados) {
                    this.regions.push(...dados.slice(0, 5).map((dado: any) => dado));
                    this.localStorageService.setItem(CURRENT_REGION, JSON.stringify(this.regions[0]));
                }
                return of(this.regions); // Return the final observable
            }),
            catchError(error => {
                console.error('Erro ao acessar a API:', error);
                return of(null); // Handle error and return an observable
            })
        );
    }

    getCurrentDDD(): string {
        let currentRegion = this.localStorageService.getItem(CURRENT_REGION);
        if (currentRegion) {
            return JSON.parse(currentRegion).ddd;
        }
        return '';
    }
}