import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../../../environments/environment';
import { ContactHelper } from '../../../utils/contact-helper';
import { RegionService } from '../../../services/region.service';

@Component({
  selector: 'app-floating-contact-whatsapp-list',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  providers: [RegionService],
  templateUrl: './floating-contact-whatsapp-list.component.html',
  styleUrl: './floating-contact-whatsapp-list.component.scss'
})
export class FloatingContactWhatsappListComponent {
  @Input() open: boolean = false;
  @Input() regions: any = [];


  whatsappNumbers!: any[];

  constructor(readonly regionService: RegionService) {}

  ngOnInit() { 
   this.loadNumbers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['regions']) {
      this.open = false;
      this.loadNumbers();
    }
  }

  loadNumbers(): void
  {
    if (environment.showAllWhatsAppNumbers) {
      this.whatsappNumbers = environment.contacts.whatsapp;
    } else {
      this.whatsappNumbers = [ContactHelper.getRandomWhatsAppNumber(this.regionService.getCurrentDDD())];
    }
  }

  openWhatsapp(whatsapp: any) {
    open(`https://wa.me/${whatsapp.number}?text=${whatsapp.message}`, '_blank');
  }
}
