<div class="lab-search-cep">
    <div class="lab-search-cep-content">
        <h1>{{ data.title }}</h1>
        <h3>{{ data.subtitle }}</h3>
    </div>
    <form [formGroup]="regionForm">
        <mat-form-field>
            <mat-label>Digite o seu CEP</mat-label>
            <input matInput (keyup)="onKeyUp($event, regionForm.get('zipcode')?.value)" placeholder="Digite o seu CEP" formControlName="zipcode">
        </mat-form-field>
    </form>
</div>