<div class="lab-navbar">
    <div class="lab-container lab-flex lab-align-items-center">
        <div class="lab-navbar-logo" *ngIf="data.logo.type == 'image'">
            <a href="#" (click)="scrollToSection($event, 'header')">
                <img src="{{ data.logo.image }}" alt="{{ data.logo.text }}">
            </a>
        </div>
        <div class="lab-navbar-logo" *ngIf="data.logo.type == 'text'">
            <strong>{{ data.logo.text }}</strong>
        </div>
        <ng-container *ngIf="!showOnlyGoBack">
            <nav class="lab-navbar-nav" [ngClass]="{'open': isMenuActive}">
                <ul class="lab-navbar-nav-list">
                    <li class="lab-navbar-nav-list-item"><a href="#" (click)="scrollToSection($event, 'assistencia-tecnica')">Assistência técnica</a></li>
                    <li class="lab-navbar-nav-list-item"><a href="#" (click)="scrollToSection($event, 'regiao-atendimento')">Região de atendimento</a></li>
                    <li class="lab-navbar-nav-list-item"><a href="#" (click)="scrollToSection($event, 'faq')">FAQ</a></li>
                </ul>
            </nav>
            <nav class="lab-navbar-contact-nav lab-align-items-end">
                <ul class="lab-navbar-contact-nav-list" *ngIf="data.contactActions.enabled">
                    <li class="lab-navbar-contact-nav-list-item" *ngFor="let action of data.contactActions.actions">
                        <app-button size="{{ action.size }}" (action)="action.action($event)" borderType="{{ action.borderType }}" icon="{{ action.icon }}" variation="{{ action.variation }}" label="{{ action.label }}"></app-button>
                    </li>
                </ul>
            </nav>
            <div class="lab-navbar-toggle-menu">
                <app-button (click)="toggleMenu()" borderType="rounded" size="small" icon="menu" variation="default" label=""></app-button>
            </div>
        </ng-container>

        <ng-container *ngIf="showOnlyGoBack">
            <nav class="lab-navbar-contact-nav lab-align-items-end">
                <ul class="lab-navbar-contact-nav-list">
                    <li class="lab-navbar-contact-nav-list-item">
                        <app-button size="small" (action)="goToHome()" borderType="pill" icon="arrow_left" variation="default" label="Voltar"></app-button>
                    </li>
                </ul>
            </nav>
        </ng-container>
    </div>
</div>