import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [NgForOf, NgIf],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {

  public data: any = {
    brand: environment.content.brand,
    product: environment.content.product,
  }

  public faqList: any[] = [
    { 
      "id": 2,
      "title": "Por que minha lava e seca não está secando as roupas completamente?",
      "content": "Isso pode ocorrer por várias razões: sobrecarga da máquina, filtros sujos, mangueiras obstruídas ou configurações inadequadas. Verifique o manual para o ciclo correto e faça manutenção regular.",
      "opened": false
    },
    { 
      "id": 3,
      "title": "Como posso resolver o problema de vazamento de água?",
      "content": "Verifique se as mangueiras estão bem conectadas e sem danos. Também é importante checar a borracha de vedação da porta e garantir que não há obstruções no filtro.",
      "opened": false
    },
    { 
      "id": 4,
      "title": "O que fazer se a máquina estiver fazendo barulhos estranhos durante o ciclo?",
      "content": "Barulhos podem indicar objetos presos no tambor ou problemas mecânicos. Pare a máquina, verifique o interior e, se o problema persistir, consulte um técnico.",
      "opened": false
    },
    { 
      "id": 5,
      "title": "Como limpar o filtro da lava e seca?",
      "content": "Consulte o manual para localizar o filtro. Normalmente, ele deve ser limpo a cada 1-3 meses. Retire o filtro, lave com água corrente e remova quaisquer detritos.",
      "opened": false
    },
    { 
      "id": 6,
      "title": "Qual é a capacidade máxima de carga da minha lava e seca?",
      "content": "A capacidade varia de acordo com o modelo. Consulte o manual do usuário para informações específicas sobre a carga máxima recomendada.",
      "opened": false
    },
    { 
      "id": 7,
      "title": "A máquina pode ser usada para lavar roupas delicadas?",
      "content": "Sim, a maioria das lava e secas possui ciclos específicos para roupas delicadas. Sempre verifique os rótulos das roupas e ajuste a configuração da máquina conforme necessário.",
      "opened": false
    },
    { 
      "id": 8,
      "title": "O que fazer se a lava e seca não estiver ligando?",
      "content": "Verifique se a máquina está conectada à tomada e se o disjuntor não foi acionado. Se tudo estiver em ordem e a máquina ainda não ligar, pode ser necessário chamar um técnico.",
      "opened": false
    },
    { 
      "id": 9,
      "title": "Como posso evitar o mau odor nas roupas após a lavagem?",
      "content": "Mantenha a máquina limpa, faça ciclos de lavagem regulares e não deixe roupas úmidas dentro por muito tempo. Utilize produtos de limpeza específicos se necessário.",
      "opened": false
    },
    { 
      "id": 10,
      "title": "Quando é necessário chamar um técnico para consertos?",
      "content": "Se você encontrar problemas recorrentes, barulhos estranhos, vazamentos ou se a máquina não funcionar adequadamente, é recomendável chamar um técnico qualificado.",
      "opened": false
    }
  ];

  toggleFaq(faq: any) {
    faq.opened = faq.opened ? false : true;
  }

}
