<div class="lab-floating-contact">
    <ul class="lab-floating-contact-list">
        <li class="lab-floating-contact-list-item">
            <app-button 
                icon="phone" 
                borderType="circle" 
                size="small" 
                variation="{{ contactPhoneVariation }}" 
                label=""
                (click)="openPhone()"
                onkeypress=""
            >
            </app-button>
        </li>
        <li class="lab-floating-contact-list-item" [ngClass]="{'active': isActive }">
            <app-floating-contact-whatsapp-list [regions]="regions" [open]="isWhatsappListActive"></app-floating-contact-whatsapp-list>
            <div class="lab-floating-contact-list-item-message">
                👋 Seja atendido imediatamente! <strong>Atendimento 24 horas.</strong>
            </div>
            <app-button 
                icon="fab-whatsapp" 
                iconType="font-awesome" 
                borderType="circle" 
                size="small" 
                variation="whatsapp" 
                label=""
                (click)="toggleWhatsappListActive()"
                onkeypress=""
            >
            </app-button>
        </li>
    </ul>
</div>
